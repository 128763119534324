import React from 'react'
import PropTypes from 'prop-types'
import * as questionStyles from './question.module.scss'

const Question = ({ content }) => {
    return <h4 className={questionStyles.question}>{content}</h4>
}

Question.propTypes = {
    content: PropTypes.string.isRequired,
}

export default Question
