import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbySeo, ArticleJsonLd } from 'gatsby-plugin-next-seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import { toLocaleDateString } from '../utils/helpers'
import Hero from '../components/hero'
import RichTextRenderer from '../components/richTextRenderer'
import Share from '../components/share'
import RelatedArticles from '../components/relatedArticles'
import Quiz from '../components/quiz'
import Comments from '../components/common/comments'
import * as articlePageStyling from './article.module.scss'

export default function ArticlePage({ data, pageContext, location }) {
    const { contentfulArticle } = data

    const quizQuestions = contentfulArticle.quiz
        ? JSON.parse(
              contentfulArticle.quiz.childContentfulQuizQuestionsJsonNode
                  .internal.content
          ).quiz
        : null

    const pageURL = `${data.site.siteMetadata.siteUrl}${location.pathname}`

    const createdAtDate = toLocaleDateString(contentfulArticle.createdAt)

    const hasImage = contentfulArticle.image

    return (
        <>
            {hasImage && (
                <GatsbySeo
                    openGraph={{
                        images: [
                            {
                                url: contentfulArticle.ogImage.gatsbyImageData
                                    .images.fallback.src,
                                width: 1200,
                                height: 630,
                                alt: 'Vinologik',
                            },
                        ],
                    }}
                />
            )}
            <GatsbySeo
                title={contentfulArticle.title}
                description={contentfulArticle.subtitle}
                openGraph={{
                    title: contentfulArticle.title,
                    description: contentfulArticle.subtitle,
                    url: pageURL,
                    type: 'article',
                    article: {
                        publishedTime: contentfulArticle.createdAt,
                        modifiedTime: contentfulArticle.updatedAt,
                        section: contentfulArticle.category
                            ? contentfulArticle.category.title
                            : '',
                        tags: contentfulArticle.tags,
                    },
                }}
            />
            <ArticleJsonLd
                url={pageURL}
                headline={contentfulArticle.title}
                datePublished={contentfulArticle.createdAt}
                dateModified={contentfulArticle.updatedAt}
                authorName={contentfulArticle.author.name}
                publisherName="Vinologik"
                description={contentfulArticle.subtitle}
                keywords={contentfulArticle.tags}
            />
            <Hero
                desktopImage={contentfulArticle.image}
                mobileImage={contentfulArticle.mobileImage}
                heading={contentfulArticle.title}
                body={contentfulArticle.subtitle}
            >
                <p className={articlePageStyling.caption}>
                    Publicerat {createdAtDate} i kategorin{' '}
                    {contentfulArticle.category !== null && (
                        <Link to={contentfulArticle.category.slug}>
                            {contentfulArticle.category.title.trim()}.
                        </Link>
                    )}
                </p>
            </Hero>
            <article className={articlePageStyling.article}>
                <RichTextRenderer
                    richText={contentfulArticle.content}
                    showFullWineDesign={true}
                    showTableOfContents={true}
                />
                <div className={articlePageStyling.credits}>
                    <GatsbyImage
                        image={contentfulArticle.author.image.gatsbyImageData}
                        alt={contentfulArticle.author.name}
                        style={{ minWidth: '64px' }}
                    />
                    <div className={articlePageStyling.creditsText}>
                        <p className={articlePageStyling.caption}>
                            Publicerat {createdAtDate} i kategorin{' '}
                            {contentfulArticle.category !== null && (
                                <Link to={contentfulArticle.category.slug}>
                                    {contentfulArticle.category.title.trim()}.
                                </Link>
                            )}
                        </p>
                        <p>Av {contentfulArticle.author.name}</p>
                        <Share url={pageURL} />
                    </div>
                </div>
            </article>
            {quizQuestions && (
                <Quiz
                    fullDesign={true}
                    questions={quizQuestions}
                    difficulty={contentfulArticle.quiz.difficulty}
                    title={contentfulArticle.quiz.title}
                    description={contentfulArticle.quiz.description}
                    contentfulId={contentfulArticle.quiz.contentful_id}
                    showShare={false}
                />
            )}
            <Comments contentfulPageId={contentfulArticle.contentful_id} />
            <RelatedArticles currentArticle={contentfulArticle} />
        </>
    )
}

export const pageQuery = graphql`
    query ($title: String!) {
        contentfulArticle(title: { eq: $title }) {
            id
            contentful_id
            title
            subtitle
            createdAt
            updatedAt
            tags
            quiz {
                contentful_id
                title
                difficulty
                description
                childContentfulQuizQuestionsJsonNode {
                    internal {
                        content
                    }
                }
            }
            category {
                slug
                title
            }
            content {
                raw
                references {
                    ... on ContentfulClass {
                        __typename
                        contentful_id
                        slug
                    }
                    ... on ContentfulArticle {
                        __typename
                        contentful_id
                        slug
                    }
                    ... on ContentfulFactBox {
                        __typename
                        contentful_id
                    }
                    ... on ContentfulAsset {
                        __typename
                        contentful_id
                        title
                        isPortrait
                        gatsbyImageData(
                            width: 1280
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                    }
                    ... on ContentfulWine {
                        ...WineTeaserFields
                    }
                    ... on ContentfulWineCarousel {
                        __typename
                        contentful_id
                    }
                }
            }
            image {
                title
                gatsbyImageData(
                    width: 1920
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
            ogImage: image {
                gatsbyImageData(
                    width: 1200
                    height: 630
                    formats: JPG
                    cropFocus: CENTER
                    resizingBehavior: FILL
                )
            }
            mobileImage {
                gatsbyImageData(
                    width: 800
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                )
            }
            author {
                name
                image {
                    gatsbyImageData(
                        width: 64
                        height: 64
                        quality: 100
                        layout: FIXED
                        placeholder: BLURRED
                    )
                }
            }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`
