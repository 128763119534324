import React from 'react'
import PropTypes from 'prop-types'
import * as questionCount from './questionCount.module.scss'

const QuestionCount = ({ counter, total }) => {
    const progressStyles = {
        width: `${((counter - 1) / total) * 100}%`,
    }

    return (
        <div className={questionCount.questionCountContainer}>
            <div className={questionCount.progressbar}>
                <div
                    style={progressStyles}
                    className={questionCount.progress}
                ></div>
            </div>
            <h5>
                Fråga <span>{counter}</span> av <span>{total}</span>
            </h5>
        </div>
    )
}

QuestionCount.propTypes = {
    counter: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
}

export default QuestionCount
