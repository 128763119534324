import React from 'react'
import PropTypes from 'prop-types'
import Question from './question'
import AnswerOption from './answerOption'
import * as quizContainerStyles from './quizContainer.module.scss'
import * as answerSheetStyling from './answerSheet.module.scss'

const AnswerSheet = ({ answeredQuestions }) => {
    return (
        <div className={answerSheetStyling.answerSheetWrapper}>
            <h3>Dina svar</h3>
            <div>
                {answeredQuestions.map((question, index) => {
                    return (
                        <div
                            key={question.question + index}
                            className={answerSheetStyling.questionWrapper}
                        >
                            <Question
                                content={index + 1 + '. ' + question.question}
                            />
                            <ul className={quizContainerStyles.answerOptions}>
                                {question.answers.map((answerOption, index) => {
                                    return (
                                        <AnswerOption
                                            key={answerOption + index}
                                            answerContent={answerOption}
                                            userPickedAnswer={
                                                answerOption ===
                                                question.answerFromUser
                                            }
                                            answerWasCorrect={
                                                question.answerWasCorrect
                                            }
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

AnswerSheet.propTypes = {
    answeredQuestions: PropTypes.array.isRequired,
}

export default AnswerSheet
