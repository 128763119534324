import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as answerOption from './answerOption.module.scss'

const AnswerOption = ({
    answerContent,
    onAnswerSelected,
    answerWasCorrect,
    userPickedAnswer,
}) => {
    const showAsCorrect = userPickedAnswer && answerWasCorrect
    const showAsIncorrect = userPickedAnswer && !answerWasCorrect
    const isInteractive = typeof onAnswerSelected !== 'undefined'

    const answerOptionClasses = classNames({
        [answerOption.answerOption]: true,
        [answerOption.isInteractive]: isInteractive,
        [answerOption.showAsCorrect]: showAsCorrect,
        [answerOption.showAsIncorrect]: showAsIncorrect,
    })

    return (
        <li className={answerOptionClasses}>
            <input
                type="radio"
                className={answerOption.radioCustomButton}
                name="radioGroup"
                id={answerContent}
                value={answerContent}
                onChange={onAnswerSelected}
            />
            <label
                className={answerOption.radioCustomLabel}
                htmlFor={answerContent}
            >
                <span className={answerOption.answerText}>{answerContent}</span>
            </label>
        </li>
    )
}

AnswerOption.propTypes = {
    answerContent: PropTypes.string.isRequired,
    onAnswerSelected: PropTypes.func,
}

export default AnswerOption
