import React from 'react'
import PropTypes from 'prop-types'
import Question from './question'
import QuestionCount from './questionCount'
import AnswerOption from './answerOption'
import * as quizContainerStyles from './quizContainer.module.scss'

const QuizContainer = (props) => {
    const renderAnswerOptions = (answerOption) => {
        return (
            <AnswerOption
                key={answerOption}
                answerContent={answerOption}
                onAnswerSelected={props.onAnswerSelected}
            />
        )
    }

    return (
        <>
            <QuestionCount
                counter={props.questionId}
                total={props.questionTotal}
            />
            <div key={props.questionId}>
                <Question content={props.question} />
                <ul className={quizContainerStyles.answerOptions}>
                    {props.answerOptions.map(renderAnswerOptions)}
                </ul>
            </div>
        </>
    )
}

QuizContainer.propTypes = {
    answer: PropTypes.string.isRequired,
    answerOptions: PropTypes.array.isRequired,
    question: PropTypes.string.isRequired,
    questionId: PropTypes.number.isRequired,
    questionTotal: PropTypes.number.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
}

export default QuizContainer
