import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import * as relatedArticlesStyling from './relatedArticles.module.scss'

const RelatedArticles = ({
    currentArticle,
    showOnlyTheseArticles,
    prioritizeTheseArticles,
    currentCategory,
    headline,
}) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulArticle(sort: { fields: [createdAt], order: DESC }) {
                edges {
                    node {
                        slug
                        id
                        title
                        createdAt(formatString: "D MMMM YYYY", locale: "sv")
                        category {
                            title
                            id
                        }
                        image {
                            gatsbyImageData(
                                width: 70
                                height: 70
                                quality: 95
                                layout: FIXED
                                placeholder: BLURRED
                                cropFocus: CENTER
                                resizingBehavior: FILL
                            )
                        }
                    }
                }
            }
        }
    `)

    let allArticles = []

    if (prioritizeTheseArticles) {
        // Vi väljer ut några artiklar att visa först
        const notPrioritizedArticles = []
        const prioritizedArticles = data.allContentfulArticle.edges.reduce(
            (prioritizedArticles, { node: article }) => {
                if (prioritizeTheseArticles.includes(article.id)) {
                    // Om vi bara ska visa specifika artiklar, så måste ID matcha för att de ska få vara med
                    prioritizedArticles.push(article)
                } else {
                    notPrioritizedArticles.push(article)
                }

                return prioritizedArticles
            },
            []
        )

        // Lägg till de icke prioriterade i slutet så att vi alltid har tillräckligt många
        allArticles = prioritizedArticles.concat(notPrioritizedArticles)
    } else {
        allArticles = data.allContentfulArticle.edges.reduce(
            (allArticles, { node: article }) => {
                if (
                    !currentArticle &&
                    !currentCategory &&
                    !showOnlyTheseArticles
                ) {
                    // Om vi inte har några preferenser så bryr vi oss inte om att filtrera.
                    allArticles.push(article)
                } else if (currentArticle && currentArticle.id !== article.id) {
                    // Om vi visar en artikel, så måste vi filtrera bort den
                    allArticles.push(article)
                } else if (
                    currentCategory &&
                    article.category &&
                    currentCategory.id !== article.category.id
                ) {
                    // Om vi visar en kategori, så måste vi ta bort alla som tillhör den kategorin
                    allArticles.push(article)
                } else if (showOnlyTheseArticles) {
                    if (showOnlyTheseArticles.includes(article.id)) {
                        // Om vi bara ska visa specifika artiklar, så måste ID matcha för att de ska få vara med
                        allArticles.push(article)
                    }
                }
                return allArticles
            },
            []
        )
    }

    allArticles = allArticles.slice(0, 8)

    return allArticles.length ? (
        <div className={relatedArticlesStyling.relatedArticlesWrapper}>
            <h3 className={relatedArticlesStyling.headline}>
                {headline ? headline : 'Fler artiklar'}
            </h3>
            <div className={relatedArticlesStyling.relatedArticles}>
                {allArticles.map((article) => (
                    <Link
                        className={relatedArticlesStyling.relatedArticle}
                        key={article.id}
                        to={article.slug}
                    >
                        <div className={relatedArticlesStyling.content}>
                            <h4 className={relatedArticlesStyling.title}>
                                {article.title}
                            </h4>
                            {article.category && (
                                <h5 className={relatedArticlesStyling.category}>
                                    {article.category.title.trim()}
                                </h5>
                            )}
                        </div>
                        {article.image && (
                            <GatsbyImage
                                image={article.image.gatsbyImageData}
                                alt={article.title}
                            />
                        )}
                    </Link>
                ))}
            </div>
        </div>
    ) : null
}

RelatedArticles.propTypes = {
    currentArticle: PropTypes.object,
    showOnlyTheseArticles: PropTypes.array,
    prioritizeTheseArticles: PropTypes.array,
    currentCategory: PropTypes.object,
    headline: PropTypes.string,
}

export default RelatedArticles
