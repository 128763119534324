import React from 'react'
import PropTypes from 'prop-types'
import Cta from '../common/cta'
import AnswerSheet from './answerSheet'
import * as resultStyling from './result.module.scss'

const Result = ({
    numberOfCorrectAnswers,
    numberOfQuestions,
    resetQuiz,
    answeredQuestions,
}) => {
    const percentageCorrect = Math.round(
        (numberOfCorrectAnswers / numberOfQuestions) * 100
    )

    const renderResultText = () => {
        if (percentageCorrect < 50) {
            return <h4>Övning ger färdighet, testa gärna igen.</h4>
        } else if (percentageCorrect >= 50 && percentageCorrect < 90) {
            return <h4>Bra jobbat! Du fick de flesta rätt och har bra koll.</h4>
        } else {
            return <h4>Riktigt bra jobbat! Du har stenkoll på det här.</h4>
        }
    }

    return (
        <div>
            <h2>
                Du fick {numberOfCorrectAnswers} av {numberOfQuestions} rätt!
            </h2>
            <div className={resultStyling.resultTextWrapper}>
                {renderResultText()}
            </div>

            <Cta
                isInverted={false}
                isLink={false}
                onClick={resetQuiz}
                text={'Starta om quiz'}
            />
            <AnswerSheet answeredQuestions={answeredQuestions} />
        </div>
    )
}

Result.propTypes = {
    numberOfCorrectAnswers: PropTypes.number.isRequired,
    numberOfQuestions: PropTypes.number.isRequired,
    resetQuiz: PropTypes.func.isRequired,
}

export default Result
