import React, { useState, useContext, useEffect } from 'react'
import { FirebaseContext } from './firebase'
import QuizContainer from './quiz/quizContainer'
import Share from './share'
import Result from './quiz/result'
import PropTypes from 'prop-types'
import * as quizStyles from './quiz.module.scss'
import classNames from 'classnames'
import { getCurrentUrl } from '../utils/helpers'

const Quiz = ({
    questions,
    title,
    description,
    difficulty,
    fullDesign,
    contentfulId,
    showShare = true,
}) => {
    const { firebase, user } = useContext(FirebaseContext)

    const [state, setState] = useState({
        questions: questions,
        contentfulId: contentfulId,
        counter: 0,
        questionId: 1,
        question: questions[0].question,
        questionObject: questions[0],
        answerOptions: questions[0].answers,
        answer: '',
        correctAnswers: 0,
        completed: false,
        answeredQuestions: [],
    })

    useEffect(() => {
        if (state.counter !== 0) {
            if (state.questionId < state.questions.length) {
                setTimeout(() => setNextQuestion(), 300)
            } else {
                setTimeout(() => quizCompleted(), 300)
            }
        }
    }, [state.counter])

    const handleAnswerSelected = (event) => {
        const answerFromUser = event.currentTarget.value
        const correctAnswerIndex = state.questionObject.correctAnswer
        const correctAnswer = state.questionObject.answers[correctAnswerIndex]
        const answerWasCorrect = answerFromUser === correctAnswer
        const correctAnswers = answerWasCorrect
            ? state.correctAnswers + 1
            : state.correctAnswers

        const counter = state.counter + 1

        const updatedState = {
            ...state,
            correctAnswers: correctAnswers,
            answer: answerFromUser,
            counter: counter,
            answeredQuestions: [
                ...state.answeredQuestions,
                {
                    ...state.questionObject,
                    answerWasCorrect,
                    answerFromUser,
                    correctAnswer,
                },
            ],
        }

        setState(updatedState)
    }

    const setNextQuestion = () => {
        const counter = state.counter
        const questionId = state.questionId + 1

        setState({
            ...state,
            questionId: questionId,
            questionObject: state.questions[counter],
            question: state.questions[counter].question,
            answerOptions: state.questions[counter].answers,
            answer: '',
        })
    }

    const resetQuiz = () => {
        setState({
            questions: questions,
            contentfulId: contentfulId,
            counter: 0,
            questionId: 1,
            question: questions[0].question,
            questionObject: questions[0],
            answerOptions: questions[0].answers,
            answer: '',
            correctAnswers: 0,
            completed: false,
            answeredQuestions: [],
        })
    }

    const quizCompleted = () => {
        setState({
            ...state,
            completed: true,
        })
        if (user && user.idToken) {
            firebase
                .postQuizResult({
                    score: state.correctAnswers,
                    numberOfQuestions: state.questions.length,
                    contentfulQuizId: state.contentfulId,
                    idToken: user.idToken,
                })
                .catch((error) => {
                    console.log('Något gick fel, försök igen.')
                    console.warn(error)
                })
        }
    }

    const renderQuiz = () => {
        return (
            <QuizContainer
                answer={state.answer}
                answerOptions={state.answerOptions}
                questionId={state.questionId}
                question={state.question}
                questionTotal={state.questions.length}
                onAnswerSelected={handleAnswerSelected}
            />
        )
    }

    const renderResult = () => {
        return (
            <Result
                numberOfCorrectAnswers={state.correctAnswers}
                numberOfQuestions={state.questions.length}
                answeredQuestions={state.answeredQuestions}
                resetQuiz={resetQuiz}
            />
        )
    }

    const quizWrapperClasses = classNames({
        [quizStyles.quizWrapper]: true,
        [quizStyles.fullDesign]: fullDesign,
    })

    return (
        <div className={quizWrapperClasses}>
            {fullDesign && (
                <div className={quizStyles.introduction}>
                    <h3 className={quizStyles.headline}>Quiz - {title}</h3>
                    {showShare && <Share url={getCurrentUrl()} />}
                    <h5>Svårighetsgrad: {difficulty}</h5>
                    {description && <p>{description}</p>}
                </div>
            )}
            {state.completed ? renderResult() : renderQuiz()}
        </div>
    )
}

Quiz.propTypes = {
    questions: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    difficulty: PropTypes.string.isRequired,
    fullDesign: PropTypes.bool.isRequired,
    contentfulId: PropTypes.string.isRequired,
}

export default Quiz
